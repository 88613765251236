<template>
  <base-section
    id="affiliates"
    space="36"
  >
    <v-container>
      <base-section-heading title="Partners" />

      <v-slide-group>
        <v-slide-item
          :key="'pure'"
          class="align-self-center"
        >
          <base-img
            :src="
              require(`@/assets/pure_cork_logo.png`)
            "
            color="grey"
            contain
            height="40"
            width="128"
          />
        </v-slide-item>
        <v-divider vertical />
        <v-slide-item
          :key="'vfi'"
          class="align-self-center"
        >
          <base-img
            :src="
              require(`@/assets/vfi_logo.jpg`)
            "
            color="grey"
            contain
            height="70"
            width="128"
          />
        </v-slide-item>

        <v-responsive
          :key="'divider-0'"
          class="text-center"
          height="56"
          width="48"
        >
          <v-divider vertical />
        </v-responsive>
        <template v-for="n in 2">
          <v-slide-item
            :key="n"
            class="align-self-center"
          >
            <base-img
              :src="
                require(`@/assets/logo-${n}-${
                  $vuetify.theme.isDark ? 'dark' : 'light'
                }.png`)
              "
              color="grey"
              contain
              height="40"
              width="128"
            />
          </v-slide-item>

          <v-responsive
            v-if="n < 3"
            :key="`divider-${n}`"
            class="text-center"
            height="56"
            width="48"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAffiliates',
  }
</script>
